import {
  cardFields,
  dynamicVersatileCardFields,
  memberGetMemberFields,
  textBlockFields,
  articleStoryFields,
  buildSectionFields,
  financialServicesBannerFields,
  merchantListFields,
  superHeroFields,
  titleCopyCtaBlockFields,
  versatileCardFields,
  buttonFields,
  carouselFavouritesFields,
} from '@/providers/contentful/contentful-graphql-fragments';

const sectionFields = buildSectionFields({
  fieldName: 'sectionFields',
  contentCollectionLimit: 15,
  contentRowItemsLimit: 10,
  versatileCard: true,
  dynamicVersatileCard: true,
  titleCopyCtaBlock: true,
  articleStory: true,
  merchantList: true,
  superHero: true,
  financialServicesBanner: true,
  button: true,
  memberGetMember: true,
  card: true,
  textBlock: true,
  carouselFavourites: true,
});

const MAX_LIMIT = 15;
export const homeSectionQuery = `query HomePageModuleQuery($contentfulOpCoId: String!, $countryId: String, $locale: String!) {
	microcopyCollection(locale: $locale, where: {
		key_in: [
		  "merchant.ratewas"
		  "merchant.rateupto"
		  "merchant.ratefrom"
		  "speedyAward.title"
		  "favourites.button.addToFavourites"
		  "favourites.button.removeFromFavourites"
		]
	  }) {
		items {
		  key
		  value
		}
	  }
	pageHomeLinkedMediaContentCollection(
	  limit: 1
	  locale: $locale
	  where: {programId: $contentfulOpCoId, countryId: $countryId}
	) {
		items {
			topSectionCollection(limit: ${MAX_LIMIT}) {
				items {
					...sectionFields
				}
			}

			bottomSectionCollection(limit: ${MAX_LIMIT}) {
				items {
					...sectionFields
				}
			}
		}
	  }
  }
${sectionFields}
${versatileCardFields}
${dynamicVersatileCardFields}
${merchantListFields}
${articleStoryFields}
${titleCopyCtaBlockFields}
${superHeroFields}
${financialServicesBannerFields}
${buttonFields}
${memberGetMemberFields}
${cardFields}
${textBlockFields}
${carouselFavouritesFields}
`;
